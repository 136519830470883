<template>
  <div class="main-price">
    <div class="main-price-head">
      <img @click="clickHandler('/')" style="margin-left: 28px" src="../assets/go-back.png" alt=""/>
      <div>价格服务说明</div>
    </div>
    <div class="main-price-content">
      <div class="main-price-content-tab">
        <img src="../assets/价格说明表2.jpg"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransProPrice",
  data(){
    return{
      hasH5: false,
    }
  },

  methods:{

    clickHandler(url){
      this.$router.push(url)
    }
  }
}
</script>

<style lang="less" scoped>
.main-price {
  min-width: 765px;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-flow: column;
  align-items: center;

  .main-price-head {
    width: 100%;
    height: 88px;
    background: #E6E6E6;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;

    div {
      flex: 1;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      text-align: center;
      margin-right: 60px;
    }
  }

  .main-price-content {
    display: flex;
    width: 709px;
    flex-flow: column;
    align-items: center;


    .main-price-content-tab {
      margin-top: 30px;
      margin-bottom: 20px;
      width: 709px;
      background: #E9EEF3;
      font-size: 24px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .main-price-content-table {
      display: flex;
      font-size: 24px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #666666;

      .one {
        width: 108px;
      }

      .two {
        width: 158px;
      }

      .three {
        width: 218px;
      }

      .four {
        width: 222px;
      }

      div {
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .main-price-content-table-row {
      display: flex;
      font-size: 24px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #666666;

      div {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .one {
        width: 108px;
      }

      .two {
        width: 158px;
      }

      .three {
        width: 218px;
      }

      .four {
        width: 222px;
      }
    }
  }
}

</style>